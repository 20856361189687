import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex } from "@chakra-ui/react"
import Layout from "../components/Layout";
import CustomContainer from "../components/UI/CustomContainer"
import Markdown from "../components/UI/Markdown"
import CustomLink from "../components/UI/CustomLink"
// const Layout = React.lazy(() => import('../components/Layout'));
// const CustomContainer = React.lazy(() => import('../components/UI/CustomContainer'));
// const Markdown = React.lazy(() => import('../components/UI/Markdown'));
// const CustomLink = React.lazy(() => import('../components/UI/CustomLink'));

const NotFoundPage = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: {settingName: {eq: "siteinfos"}}) {
          frontmatter {
            page404 {
              title
              text
            }
          }
        }
      }
    `
  );
  const data = markdownRemark.frontmatter || {};
  const title = data.page404.title || "Page non trouvée"
  return (
    <Layout title={title}>
      <CustomContainer py={[10,16,20]}>
        <Box>
          <Box textAlign="center"><Markdown>{data.page404.text}</Markdown></Box>
          <Flex justify="center" mt={[4,4,5]}><CustomLink href="/">Aller à la page d'acceuil</CustomLink></Flex>
        </Box>
      </CustomContainer>
    </Layout>
  );
};

export default NotFoundPage
